//UTILITIES
import React, { component, useEffect, useState } from "react";
import APIURL from "./APIURL";
import IdleTimer from "react-idle-timer";
import { IdleTimeOutModal } from "./IdleModal";
import {
  BrowserRouter as Router,
  Route,
  withRouter,
  Switch,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  edit_subject,
  approve_subject,
  view_all_subject,
  view_subject,
  add_subject,
  add_study,
  edit_study,
  view_study,
  view_user_setting,
  add_user_setting,
  edit_user_setting,
  user_detail,
  islogged,
  receive_email,
} from "./redux/action";

//Components imports
import AppTopBar from "./AppTopBar";

//users and roles
import UserProfile from "./authentication/user/UserProfile";
import ListRole from "./authentication/role/ListRole";
import AddRole from "./authentication/role/AddRole";
import ListUser from "./authentication/user/ListUser";
import AddUser from "./authentication/user/AddUser";

//setting
import CompanyProfile from "./setting/companysetting/companyprofile";
//study
import ListStudy from "./study/ListStudy";
import AddStudyy from "./study/AddStudyy";
import DetailStudy from "./study/DetailStudy";

//study subject
import ListStudySubject from "./studysubject/ListStudySubject";
import TrialMap from "./studysubject/TrialMap";
import Excel from "./studysubject/Excel_old";
import AllExcel from "./studysubject/AllExcel";
// import Test from "./studysubject/Test";
// import Excel3 from "./studysubject/Excel"
//SCREENING
import ApproveStudySubject from "./studysubject/ApproveStudySubject";
import InformedStudySubject from "./studysubject/InformedStudySubject";
import AddInclusionExclusion from "./screening/AddInclusionExclusion";
import AddDemography from "./screening/AddDemography";

//BASELINE
import ListVitalSigns from "./baseline/ListVitalSigns";
import ListCancerHistory from "./baseline/ListCancerHistory";
import ListMedicalHistory from "./baseline/ListMedicalHistory";
import ListMetastaticSites from "./baseline/ListMetastaticSites";
import ListPrognosticFactors from "./baseline/ListPrognosticFactors";
import ListSmokingHistory from "./baseline/ListSmokingHistory";
import ListConcomitantMedication from "./baseline/ListConcomitantMedication";
import ListLaboratoryValues from "./baseline/ListLaboratoryValues";
import ListCurrentTherapy from "./baseline/ListCurrentTherapy";

//Treatment
import ListTreatment from "./treatment/ListTreatment";

//login
import Login from "./authentication/Login";

//settings
import Settings from "./setting/Settings";

//modification
import ListModifications from "./modification/ListModifications";

//error handling
import NotFound from "./common/commoncomponents/NotFound";

//No Permission
import ViewNoPermission from "./common/commoncomponents/ViewNoPermission";
import OtherViewPermission from "./common/commoncomponents/OtherViewPermission";
import { handleTreeViewCheckChange } from "@progress/kendo-react-treeview";
import axios from "axios";
import SectionLocked from "./common/commoncomponents/SectionLocked";

export function AppRouter(props) {
  //redux permissions
  const UserDetail = useSelector((state) => state.UserDetail);
  const ViewStudy = useSelector((state) => state.ViewStudy);
  const AddStudy = useSelector((state) => state.AddStudy);
  const EditStudy = useSelector((state) => state.EditStudy);
  const ViewSubject = useSelector((state) => state.ViewSubject);
  const ViewUserSetting = useSelector((state) => state.ViewUserSetting);
  const AddUserSetting = useSelector((state) => state.AddUserSetting);
  const EditUserSetting = useSelector((state) => state.EditUserSetting);
  const ApproveSubject = useSelector((state) => state.ApproveSubject);

  const dispatch = useDispatch();
  const [timeout, settimeout] = useState(1000 * 60 * 100);
  const [showModal, setshowModal] = useState(false);
  const [isTimedOut, setisTimedOut] = useState(false);
  const [idleTimer, setidleTimer] = useState(null);

  const onAction = (e) => {
    setisTimedOut(false);
  };

  const onActive = (e) => {
    setisTimedOut(false);
  };

  const onIdle = (e) => {
    if (isTimedOut) {
      dispatch(islogged(false));

      dispatch(add_subject(false));
      dispatch(edit_subject(false));
      dispatch(approve_subject(false));
      dispatch(view_subject(false));
      dispatch(view_all_subject(false));

      dispatch(view_study(false));
      dispatch(add_study(false));
      dispatch(edit_study(false));

      dispatch(view_user_setting(false));
      dispatch(add_user_setting(false));
      dispatch(edit_user_setting(false));

      dispatch(receive_email(false));
      dispatch(
        user_detail({
          id: "",
          username: "",
          email: "",
          first_name: "",
          last_name: "",
          role: "",
          sub_investigator_fn: "",
          sub_investigator_ln: "",
          is_assistant: false,
          is_active: "",
          role_id: "",
          country: "",
          center: "",
          study_id: "",
          study_name: "",
        })
      );
    } else {
      setshowModal(true);
      idleTimer.reset();
      setisTimedOut(true);
    }
  };

  const handleClose = () => {
    setshowModal(false);
  };

  const handleLogout = () => {
    setshowModal(false);
    dispatch(islogged(false));
    dispatch(add_subject(false));
    dispatch(edit_subject(false));
    dispatch(approve_subject(false));
    dispatch(view_subject(false));
    dispatch(view_all_subject(false));

    dispatch(view_study(false));
    dispatch(add_study(false));
    dispatch(edit_study(false));

    dispatch(view_user_setting(false));
    dispatch(add_user_setting(false));
    dispatch(edit_user_setting(false));
    dispatch(receive_email(false));
    dispatch(
      user_detail({
        id: "",
        username: "",
        email: "",
        first_name: "",
        last_name: "",
        role: "",
        is_assistant: false,
        sub_investigator_ln: "",
        sub_investigator_fn: "",
        is_active: "",
        role_id: "",
        country: "",
        center: "",
        study_id: "",
        study_name: "",
      })
    );
  };

  return (
    <AppTopBar>
      <IdleTimer
        ref={(ref) => {
          setidleTimer(ref);
        }}
        element={document}
        onActive={onActive}
        onIdle={onIdle}
        onAction={onAction}
        debounce={250}
        timeout={timeout}
      />
      <IdleTimeOutModal
        showModal={showModal}
        handleClose={handleClose}
        handleLogout={handleLogout}
      />
      <Switch>
        {/* <Route path="*" component={NotFound} /> */}
        {/* LOGIN */}
        <Route exact={true} path="/login" name="login" component={Login} />

        {/* SETTINGS */}
        <Route
          exact={true}
          path="/settings"
          name="settings"
          component={Settings}
        />

        {/* USER AND ROLE */}
        <Route
          exact={true}
          path="/authentication/user/userprofile"
          component={UserProfile}
        />

        <Route
          exact={true}
          path="/settings/authentication/user/list"
          name="user"
          component={ViewUserSetting ? ListUser : OtherViewPermission}
        />
        <Route
          exact={true}
          path="/settings/authentication/role/list"
          name="role"
          component={ViewUserSetting ? ListRole : OtherViewPermission}
        />
        <Route
          exact={true}
          path="/settings/authentication/user/add"
          name="adduser"
          component={AddUserSetting ? AddUser : OtherViewPermission}
        />
        <Route
          exact={true}
          path="/settings/authentication/user/edit"
          name="edituser"
          component={EditUserSetting ? AddUser : OtherViewPermission}
        />
        <Route
          exact={true}
          path="/settings/authentication/role/add"
          name="addrole"
          component={AddUserSetting ? AddRole : OtherViewPermission}
        />
        <Route
          exact={true}
          path="/settings/authentication/role/edit"
          name="editrole"
          component={EditUserSetting ? AddRole : OtherViewPermission}
        />

        {/* SETTING COMPANY */}
        <Route
          exact={true}
          path="/settings/companyprofile"
          name="companysetting"
          component={EditUserSetting ? CompanyProfile : OtherViewPermission}
        />

        {/* STUDY */}
        <Route
          exact={true}
          path="/crf/study/list"
          name="study"
          component={ViewStudy ? ListStudy : OtherViewPermission}
        />
        <Route
          exact={true}
          path="/crf/study/add"
          name="addstudy"
          component={AddStudy ? AddStudyy : OtherViewPermission}
        />
        <Route
          exact={true}
          path="/crf/study/edit"
          name="editstudy"
          component={EditStudy ? AddStudyy : OtherViewPermission}
        />
        <Route
          exact={true}
          path="/crf/study/detail"
          name="detailstudy"
          component={DetailStudy}
        />

        {/* STUDY SUBJECT */}
        <Route
          exact={true}
          path="/crf/studysubject/list"
          name="studysubject"
          component={ViewSubject ? ListStudySubject : ViewNoPermission}
        />

        {/* david test SUBJECT */}
        <Route
          exact={true}
          path="/crf/excel/"
          name="excel"
          component={ViewSubject ? Excel : ViewNoPermission}
        />
        <Route
          exact={true}
          path="/crf/allexcel"
          name="allexcel"
          component={ViewSubject ? AllExcel : ViewNoPermission}
        />
        {/* <Route
          exact={true}
          path="/crf/Test"
          name="Test"
          component={ViewSubject ? Test : ViewNoPermission}
        /> */}

        {/* SCREENING */}
        <Route
          exact={true}
          path="/crf/screening/demography/add"
          name="screeningdemography"
          component={ViewSubject ? AddDemography : ViewNoPermission}
        />

        <Route
          exact={true}
          path="/crf/screening/inclusionexclusioncriteria/add"
          name="screeninginclusionexlusion"
          component={ViewSubject ? AddInclusionExclusion : ViewNoPermission}
        />

        {/* Informed Consent */}

        <Route
          exact={true}
          path="/crf/screening/informedconsent/add"
          name="informedconsent"
          component={ViewSubject ? InformedStudySubject : ViewNoPermission}
        />

        {/* Approve Trial  */}
        <Route
          exact={true}
          path="/trialmap"
          name="trialmap"
          component={ViewSubject ? TrialMap : ViewNoPermission}
        />
        <Route
          exact={true}
          path="/crf/screening/approvetrial/add"
          name="approvetrial"
          component={ApproveSubject ? ApproveStudySubject : ViewNoPermission}
        />

        {/* BASELINE  */}
        <Route
          exact={true}
          path="/crf/baseline/vitalsigns/add"
          name="vitalsigns"
          component={ViewSubject ? ListVitalSigns : ViewNoPermission}
        />
        <Route
          exact={true}
          path="/crf/baseline/prognosticfactors/add"
          name="prognosticfactors"
          component={ViewSubject ? ListPrognosticFactors : ViewNoPermission}
        />
        <Route
          exact={true}
          path="/crf/baseline/smokinghistory/add"
          name="smokinghistory"
          component={ViewSubject ? ListSmokingHistory : ViewNoPermission}
        />
        <Route
          exact={true}
          path="/crf/baseline/mrcchistory/add"
          name="mrcchistory"
          component={ViewSubject ? ListCancerHistory : ViewNoPermission}
        />
        <Route
          exact={true}
          path="/crf/baseline/medicalhistory/add"
          name="medicalhistory"
          component={ViewSubject ? ListMedicalHistory : ViewNoPermission}
        />
        <Route
          exact={true}
          path="/crf/baseline/metastaticsites/add"
          name="metastaticsites"
          component={ViewSubject ? ListMetastaticSites : ViewNoPermission}
        />
        <Route
          exact={true}
          path="/crf/baseline/concomitantmedications/add"
          name="concomitantmedication"
          component={ViewSubject ? ListConcomitantMedication : ViewNoPermission}
        />
        <Route
          exact={true}
          path="/crf/baseline/laboratorytests/add"
          name="laboratorytests"
          component={ViewSubject ? ListLaboratoryValues : ViewNoPermission}
        />
        <Route
          exact={true}
          path="/crf/baseline/currenttherapy/add"
          name="currenttherapy"
          component={ViewSubject ? ListCurrentTherapy : ViewNoPermission}
        />

        <Route
          exact={true}
          path="/crf/:str/:str/add"
          name="treatment"
          component={ViewSubject ? ListTreatment : ViewNoPermission}
        />

        <Route
          exact={true}
          path="/crf/listmodification"
          name="modifications"
          component={ListModifications}
        />

        {/* permission */}
        <Route
          exact={true}
          path="/nopermission"
          name="nopermission"
          component={OtherViewPermission}
        />

        <Route
          exact={true}
          path="/sectionlocked"
          name="sectionlocked"
          component={SectionLocked}
        />
      </Switch>
    </AppTopBar>
  );
}

export default withRouter(AppRouter);
